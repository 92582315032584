<template>
    <v-container fluid>
        <v-row>
            <v-col sm="12">
                <h1 class="title font-weight-light">
                    <v-icon @click="onCancelAsync">mdi-arrow-left-circle</v-icon>
                    Task : <span class="subtitle-1 ml-2 primary--text">{{taskDetails.Name}}</span>
                </h1>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card :loading="isBusy">
                    <v-container>
                        <v-tabs v-model="tab">
                            <v-tab v-if="shouldShowAssignToMe">Task Assigment</v-tab>
                            <v-tab v-if="shouldShowTaskDetails">Task Details</v-tab>
                            <v-tab v-if="shouldShowTaskForm">Task Form</v-tab>
                            <v-tab v-if="shouldShowReassign">Task Reassign</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item v-if="shouldShowAssignToMe">
                                <v-card flat>
                                    <v-card-text>
                                        <p>
                                            This task has not been assigned to a user as yet. <br>
                                            Please select an option below?
                                        </p>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn color="primary" @click="assignToMe()" style="width: 140px;">
                                            Assign To Me
                                        </v-btn>
                                        <v-btn @click="viewTask()" style="width: 140px;">
                                            View Task
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item v-if="shouldShowTaskDetails">
                                <v-card flat>
                                    <v-card-subtitle>
                                        <p v-if="taskDetails.Description">
                                            {{ taskDetails.Description }}
                                        </p>
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <v-record-preview :propSubmissionIds="submissionIds">
                                        </v-record-preview>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item v-if="shouldShowTaskForm">
                                <v-act v-if="formId !== null"
                                    :propId="formId"
                                    :propRouteOnSave="true"
                                    :propTaskForm='true'
                                    @on-route="onRoute"
                                    @on-save="onSave">
                                </v-act>
                            </v-tab-item>
                            <v-tab-item v-if="shouldShowReassign">
                                <v-card flat>
                                    <v-card-text>
                                        <p>
                                            Reassign the task to another user.
                                        </p>
                                        <v-select label="Select User"
                                            v-model="taskRoleUser"
                                            :items="taskRoleUserList"
                                            item-text="UserFullName"
                                            item-value="UserId">
                                        </v-select>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn color="primary" @click="assignToUser()" style="width: 140px;" :disabled="!taskRoleUser">
                                            Assign
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Act from '../Survey/Act';
import RecordPreview from '../../controls/RecordPreview';
import { mapState } from 'vuex';

export default {
    name: 'TaskAct',
    components: {
        'v-act': Act,
        'v-record-preview': RecordPreview
    },
    data: () => ({
        isBusy: false,
        formId: null,
        tab: 0,
        submissionIds: [],
        taskDetails: {},
        isViewOnly: false,
        taskRoleUserList: [],
        taskRoleUser: null
    }),
    mounted () {
        if (this.$route.query.id) {
            this.formId = this.$route.query.id;
        }
        if (this.$route.query.taskId) {
            this.taskId = this.$route.query.taskId;
            this.getTaskDetails(this.taskId);
        }
    },
    methods: {
        async getTaskDetails (taskId) {
            this.taskDetails = {};
            this.taskRoleUser = null;
            if (!this.viewProject._id) return;
            this.isBusy = true;
            this.$showProgress();
            this.searchText = '';
            try {
                const res = await this.$http.get(`/Task/${taskId}`);
                if (!res.data.d) {
                    this.$info('Data Load', 'Unable to retrieve tasks.');
                    this.taskDetails = {};
                }
                else {
                    this.taskDetails = res.data.d;
                    this.dialogFormIds = [];
                    this.submissionIds = [];
                    for (let index = 0; index < this.taskDetails.WorkflowMetaData.length; index++) {
                        const element = this.taskDetails.WorkflowMetaData[index];
                        this.submissionIds.push(element.AnswerId);
                    }

                    if (this.taskDetails.CanReassign) {
                        await this.getTaskRoleUsers();
                    }
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.$hideProgress();
            }
        },
        async getTaskRoleUsers () {
            const res = await this.$http.get(`/Role/${this.taskDetails.RoleId}/users`);
            if (!res.data.d) {
                this.$info('Data Load', 'Unable to retrieve task role users.');
                this.taskRoleUserList = [];
            }
            else {
                let taskUsers = res.data.d;
                // Exclude current assignee if exists
                if (this.taskDetails.AssigneeId) {
                    taskUsers = res.data.d.filter(x => x.UserId.toString() !== this.taskDetails.AssigneeId.toString());
                }
                this.taskRoleUserList = taskUsers;
            }
        },
        onSave (data, fileUploads) {
        },
        async onRoute () {
            // lets just set the task status to complete before we route
            await this.setTaskStatus('C');
            const route = '/TaskList';
            this.$router.push({ path: route }).catch(err => {
                // Check if error is for nav to same location, do nothing, otherwise throw the error.
                if (err.name !== 'NavigationDuplicated') throw err;
            });
        },
        async setTaskStatus (status) {
            try {
                const input = {
                    _id: this.taskId,
                    StatusId: status.toUpperCase()
                };
                const res = await this.$http.put('/Task', input);
                if (!res.data.d) {
                    this.$info('Data Save', 'Task status could not be updated.');
                    this.taskDetails = {};
                }
                else {
                    this.taskDetails = res.data.d;
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        async onCancelAsync () {
            this.$router.go(-1);
        },
        viewTask () {
            this.isViewOnly = true;
        },
        async assignToMe () {
            this.taskDetails.AssigneeId = this.user._id;

            try {
                const input = {
                    _id: this.taskId,
                    assigneeId: this.user._id
                };
                const res = await this.$http.put('/Task/assign', input);
                if (!res.data.d) {
                    this.$info('Data Save', 'Task assignee could not be updated.');
                    return;
                }

                this.getTaskDetails(this.taskId);
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        async assignToUser () {
            if (!this.taskRoleUser) return;

            this.taskDetails.AssigneeId = this.taskRoleUser;

            try {
                const input = {
                    _id: this.taskId,
                    assigneeId: this.taskRoleUser
                };
                const res = await this.$http.put('/Task/assign', input);
                if (!res.data.d) {
                    this.$info('Data Save', 'Task assignee could not be updated.');
                    return;
                }

                this.getTaskDetails(this.taskId);
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        }
    },
    watch: {
        viewProject () {
            // we do this to ensure we always have the right task loaded if the viewproject loaded
            if (this.taskId) this.getTaskDetails(this.taskId);
        }
    },
    computed: {
        shouldShowAssignToMe () {
            // Should be shown if:
            // - the task does not have an Assignee as yet
            // - the task is a 'Role Task'
            // - the current user has the task Role
            // - its not view only
            if (this.taskDetails.AssigneeId) return false;
            if (!this.taskDetails.RoleId) return false;
            if (!this.user.Roles.includes(this.taskDetails.RoleId)) return false;
            if (this.isViewOnly === true) return false;
            return true;
        },
        shouldShowTaskDetails () {
            // Should be shown if:
            // - only if the task has an Assignee (even if its not the same logged in user)
            // - the current user has the task Role (if no Assignee) and its not view only
            // - if its viewOnly
            if (this.taskDetails.AssigneeId) return true;
            if (this.user.Roles.includes(this.taskDetails.RoleId) && this.isViewOnly === true) return true;
            if (this.isViewOnly === true) return true;
            return false;
        },
        shouldShowTaskForm () {
            // Should be shown if:
            // - the task has an assignee, and that assignee is the current uers
            if (!this.taskDetails.AssigneeId) return false;
            if (this.taskDetails.AssigneeId !== this.user._id) return false;
            return true;
        },
        shouldShowReassign () {
            // Should be shown if:
            // - if current user has TASK_REASSIGNMENT permission in any of their roles
            // - and there is at least 1 user the task can be assigned to
            return this.taskDetails.CanReassign && this.taskRoleUserList.length > 0;
        },
        ...mapState({
            viewProject: 'viewProject',
            user: 'user'
        })
    }
};
</script>
